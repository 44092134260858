<template>
  <vx-card title="Manage Collection Data">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Collection Plan ID</label>
        <vs-input class="w-full" v-model="code" type="text" readonly />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Territory</label>
        <vs-input class="w-full" v-model="territory" type="text" readonly />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Collector / Sales</label>
        <vs-input
          class="w-full"
          v-model="collector_sales"
          type="text"
          readonly
        />
      </div>
    </div>

    <!-- table collection plan -->
    <vs-tabs>
      <vs-tab label="List">
        <div class="vx-row mb-12">
          <div class="core vx-col md:w-1/1 w-full mb-base">
            <vs-table
              search
              stripe
              border
              description
              :sst="false"
              :data="table.data"
              :max-items="table.length"
              :total="table.total"
            >
              <template slot="thead">
                <vs-th>Customer ID / Name</vs-th>
                <vs-th>Total Payment</vs-th>
                <vs-th>Invoice Code</vs-th>
                <vs-th>Invoice Info</vs-th>
                <vs-th>Due Date</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    {{ tr.customer_code }} - {{ tr.customer_name }}</vs-td
                  >
                  <vs-td>{{ tr.total_payment | formatNumber }}</vs-td>
                  <vs-td>{{ tr.invoice_no }}</vs-td>
                  <vs-td>
                    Number: {{ tr.invoice_no }} <br />
                    Date: {{ tr.invoice_date | formatDate }} <br />
                    Outstanding: {{ tr.outstanding_value | formatNumber }}
                    <br />
                    Total: {{ tr.bill_total_value | formatNumber }}
                  </vs-td>
                  <vs-td>{{ tr.due_date | formatDate }}</vs-td>

                  <!-- expand payment info -->
                  <template slot="expand">
                    <div class="vx-row" :style="{ overflow: 'auto' }">
                      <div class="vx-col mb-2">
                        <h4>Payment Info</h4>
                      </div>
                      <div class="vx-col">
                        <vs-table
                          :style="{ width: '200%' }"
                          stripe
                          border
                          :sst="false"
                          :data="tr.customer_order_payments"
                          :total="tr.customer_order_payments.length"
                          :searchable="false"
                        >
                          <template slot="thead">
                            <vs-th>Payment Method</vs-th>
                            <vs-th>Notes</vs-th>
                            <vs-th>Payment Value</vs-th>
                            <vs-th>No Payment Reason</vs-th>
                            <vs-th>Promise Date</vs-th>
                            <vs-th>Number (Giro)</vs-th>
                            <vs-th>Transfer Date (Giro)</vs-th>
                            <vs-th>Bank Name</vs-th>
                            <vs-th>Deduction Value</vs-th>
                            <vs-th>Deduction Reason</vs-th>
                            <vs-th>Photo Proof of Payment</vs-th>
                          </template>

                          <template slot-scope="{ data }">
                            <vs-tr
                              :data="tr"
                              :key="indextr"
                              v-for="(tr, indextr) in data"
                            >
                              <vs-td>
                                <b>{{ tr.payment_method_name | checkName }}</b>
                              </vs-td>
                              <vs-td>
                                <vs-textarea
                                  class="w-full"
                                  type="number"
                                  style="margin-bottom: 60px"
                                  readonly
                                  v-model="tr.notes"
                                />
                              </vs-td>
                              <vs-td>
                                <b>{{ tr.payment_value | formatNumber }}</b>
                              </vs-td>
                              <vs-td>
                                <b>{{ tr.no_payment_reason }}</b>
                              </vs-td>
                              <vs-td>
                                <b>{{ tr.promise_date | formatDate }}</b>
                              </vs-td>
                              <vs-td>
                                <b>{{ tr.giro_number }}</b>
                              </vs-td>
                              <vs-td>
                                <b>{{ tr.giro_transfer_date | formatDate }}</b>
                              </vs-td>
                              <vs-td>
                                <b>{{ tr.bank_name }}</b>
                              </vs-td>
                              <vs-td>
                                <b>{{ tr.deduction_value }}</b>
                              </vs-td>
                              <vs-td>
                                <b>{{ tr.deduction_reason }}</b>
                              </vs-td>
                              <vs-td>
                                <a
                                  v-show="tr.photo_proof_of_payment != ''"
                                  :href="tr.photo_proof_of_payment"
                                  target="_blank"
                                >
                                  <vs-button
                                    size="small"
                                    color="success"
                                    icon-pack="feather"
                                    icon="icon-eye"
                                  />
                                </a>
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                      </div>
                    </div>
                  </template>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>

        <div class="vx-row gap-2">
          <vs-button
            @click="() => $router.push('/collection-plan-manage')"
            size="small"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            >Cancel</vs-button
          >
          <vs-button
            v-show="!hideBtnApproved"
            @click="onClickBtnConfirmToApproved"
            size="small"
            color="success"
            icon-pack="feather"
            icon="icon-check"
            title="Approved"
            >Confirm to Approved</vs-button
          >
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import moment from "moment";
import PaymentForm from "./payment-form.vue";
import { formatNumber } from "../../../utils/common";

export default {
  components: {
    PaymentForm,
  },
  data() {
    return {
      // main data
      uuid: null,
      code: null,
      territory: null,
      collector_sales: null,
      data: null,
      table: this.defaultTable(),
      //   modal edit
      modalEdit: {
        index: null,
        activeModalEdit: false,
        giro_check: null,
        transfer_date: null,
      },

      // option bank
      selectedBank: null,
      optionBank: [],
      // option payment method
      optionPaymentMethod: [],
      selectedPaymentMethod: null,
      collectionPlanLineIds: [],
      isHasChangeValue: false,
      hideBtnApproved: false,
    };
  },
  methods: {
    doEnableButtonSave() {
      this.isHasChangeValue = true;
    },
    onSelectPaymentMethod(id, tr) {
      this.table.data.forEach((item) => {
        if (item.id == tr.id) {
          item.new_payment_method = id;
        }
      });
    },
    fetchPaymentMethod(params) {
      this.$vs.loading();
      this.$http
        .get(`api/sfa/v1/mobile-preparation/payment-method`, { params: params })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            // renamce COD to Cash
            this.optionPaymentMethod = resp.data.map((item) => {
              if (item.code == "COD") {
                item.name = "Cash";
              }
              return item;
            });
          }
        });
    },
    onSearchPaymentMethod(query) {
      this.fetchPaymentMethod({
        search: query,
        is_mobile: true,
      });
    },
    customLabelPaymentMethod(option) {
      return `${option.name}`;
    },
    onCancelModalEdit() {
      this.modalEdit.activeModalEdit = false;
    },
    onAcceptModalEdit() {
      this.modalEdit.activeModalEdit = false;
      // assign value
      this.data.routes[this.modalEdit.index].giro_number =
        this.modalEdit.giro_check;
      this.data.routes[this.modalEdit.index].giro_transfer_bank_id =
        this.selectedBank.id;
      this.data.routes[this.modalEdit.index].giro_transfer_date =
        this.modalEdit.transfer_date;
    },
    onCloseModalEdit() {
      this.modalEdit.activeModalEdit = false;
    },

    // main method
    async onClickBtnSave() {
      let payload = {
        collection_plan_lines: this.data.routes.map((item) => {
          return item.customer_order_payments.map((child) => {
            return {
              collection_plan_line_id: item.collection_plan_line_id,
              customer_order_payment_id: child.customer_order_payment_id,
              deduction_value: parseFloat(child.deduction_value),
              deduction_reason: child.deduction_reason,
              giro_number: child.giro_number,
              giro_transfer_bank_id: child.giro_transfer_bank_id,
              giro_transfer_date: child.giro_transfer_date,
              return_document: child.return_document,
              new_payment_method: child.new_payment_method_id,
              new_payment_value: parseFloat(child.new_payment_value),
              new_deduction_value: parseFloat(child.new_deduction_value),
              new_deduction_reason: child.new_deduction_reason,
              new_giro_number: child.new_giro_number,
              new_giro_transfer_date: child.new_giro_transfer_date,
            };
          });
        }),
      };
      payload.collection_plan_lines = payload.collection_plan_lines.flat();

      this.$vs.loading();
      const id = this.$route.params.id;
      let response = await this.$http.put(
        `/api/sfa/v1/collection-plan/manage/${id}`,
        payload
      );

      if (response.code == 200) {
        this.$vs.notify({
          title: "Success",
          text: "Data has been saved",
          color: "success",
        });
        this.$vs.loading.close();
        this.$router.push("/collection-plan-manage");
      } else {
        this.$vs.notify({
          title: "Failed",
          text: "Data failed to save",
          color: "danger",
        });
        this.$vs.loading.close();
      }
    },
    async onClickBtnEdit(tr, index) {
      this.modalEdit.index = index;
      this.modalEdit.activeModalEdit = true;
      this.modalEdit.giro_check = tr.giro_number;
      this.modalEdit.transfer_date = moment(tr.giro_transfer_date).format(
        "YYYY-MM-DD"
      );
      if (tr.giro_transfer_bank_id != 0) {
        const bank = await this.$http.get(
          `/api/sfa/v1/bank/${tr.giro_transfer_bank_id}`
        );

        this.selectedBank = bank.data;
      }
    },
    async fetchCollectionPlan() {},
    async customLabelCollectionPlan(option) {
      return `${option.uuid} - ${option.name}`;
    },
    defaultTable() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      const id = this.$route.params.id;
      this.$vs.loading();
      let response = await this.$http.get(`/api/sfa/v1/collection-plan/${id}`);

      let data = response.data;

      this.data = data;
      this.uuid = data.uuid;
      this.code = data.code;
      this.territory = `${data.territory_code} - ${data.territory_name}`;
      this.collector_sales = `${data.sales_number} - ${data.sales_name}`;
      this.table.data = data.routes;

      // set collection plan line id
      this.collectionPlanLineIds = data.routes.map((item) => {
        return item.collection_plan_line_id;
      });

      this.$vs.loading.close();
    },

    // bank
    async getOptionBank(params) {
      this.$vs.loading();
      let response = await this.$http.get(`/api/sfa/v1/bank`, params);

      let data = response.data;

      this.optionBank = data.records;

      this.$vs.loading.close();
    },
    customLabelBank(option) {
      return `${option.code} - ${option.name}`;
    },
    onSearchBank(search) {
      if (search.length > 2) {
        let params = {
          params: {
            search: search,
          },
        };
        this.getOptionBank(params);
      }
    },
    onClickBtnConfirmToApproved() {
      let collection_plan_ids = [this.$route.params.id];
      this.$vs.loading();

      this.$http
        .post("api/sfa/v1/collection-plan/multiple-approval", {
          ids: collection_plan_ids,
        })
        .then((response) => {
          this.$vs.loading.close();
          if (response.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Collection plan has been approved",
              color: "success",
              position: "top-right",
            });

            this.$refs.table_released.getData({
              status: 1,
              is_released_list: true,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.message,
              color: "danger",
              position: "top-right",
            });
          }
        });
    },
  },
  async mounted() {
    let qHideApproved = this.$route.query.hide_approved;
    this.hideBtnApproved = qHideApproved ? true : false;

    await this.getData();
    await this.getOptionBank();
    await this.fetchPaymentMethod({
      is_mobile: true,
    });
  },
  filters: {
    checkName(val) {
      if (val == "COD (Cash On Delivery)") {
        return "Cash";
      }
      return val;
    },
    formatNumber(val) {
      if (val) {
        return formatNumber(val);
      }
    },
    formatDate(value) {
      if (value == "") {
        return "-";
      } else {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
};
</script>
